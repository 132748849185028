.obs {
    background-color: black;
    color: white;
}

.obs.obs-grid {
    border-color: white;
}

textarea {
    background-color: rgba(10, 2, 36, 0.8);
    border-radius: 0.2rem;
    font-family: inherit;
    color: white;
    border: 0.125rem solid #4C4C7E
}
